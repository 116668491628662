<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 border-bottom">
            <div class="d-flex align-items-center">
              <div class="me-2"><i class="uil uil-usd-circle display-6 text-primary"></i></div>
              <div>
                <h4 class="fw-medium font-size-20 text-info mb-0"> {{ title }}</h4>
                <small class="d-block font-size-13 text-muted fw-normal">IMPS and UPI Account List</small>
              </div>
             
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-12">
                <h4 class="fw-medium font-size-18 text-info mb-3 p-0">UPI Account List</h4>
              </div>
              <div class="col-12">
                <div class="d-md-flex">
                    <div class="d-flex">
                      <div class="app-search2 position-relative">
                        <input
                          type="search"
                          placeholder="Filter..."
                          class="form-control mb-3"
                          v-model.trim="searchKey"
                          autocomplete="no"
                          @keyup="filterByUPI()"
                        />
                        <span class="uil-search text-info"></span>
                      </div>
                      <button
                        class="btn btn-light mb-3 ms-2 text-nowrap"
                        type="button"
                        @click="clear()"
                      >
                        <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="ms-auto mb-3 mb-md-0 text-end">
                    <button type="button" class="btn btn-info fw-medium rounded" @click="$refs['modal-addupi'].show()"><i class="uil uil-plus-circle me-1"></i> Add UPI Account</button>
                  </div>
                  
                </div>
              </div>
              <div class="col-12 col-lg-auto d-none">
                <div class="row">
                  <div class="col-6 pe-1 d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                <div class="table-responsive text-nowrap font-size-14">
                  <table class="table mb-3 mb-0 align-middle min-width-760" :class="{'table-hover':returnData.length>0}">
                    <thead class="text-uppercase bg-info text-white">
                      <tr>
                        <th>UPI ID</th>
                        <th>UPI Name</th>
                        <th>Type</th>
                        <th class="text-end">Transaction Limit</th>
                        <th class="text-end" width="120">Active</th>
                        <th class="text-end" width="120">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="!filterByUPI().length && !loading && searchKey">
                        <td colspan="6" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i>  Sorry, we couldn't
                          find any results of "{{ searchKey }}".
                        </td>
                      </tr>
                      <tr v-if="!returnData.length && !loading">
                        <td colspan="6" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading">
                        <td colspan="6" class="text-center text-muted" height="300">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      <tr v-for="(value, index) in filterByUPI()" :key="index">
                        <td>
                          {{ value.upi_id }}
                        </td>
                        <td>
                          {{ value.upi_name }}
                        </td>
                        <td>
                          {{ value.type }}
                        </td>
                        <td class="text-end">
                          {{ convertedTransactionLimit(value.transaction_limit) }}
                        </td>
                        <td class="text-end">
                          <div class="form-check form-switch form-switch-md float-end">
                            <input class="form-check-input" type="checkbox" @change="updateUPI(value)" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="submitted">
                          </div>
                        </td>
                        <td class="text-end">
                          <button type="button" class="btn btn-outline-danger btn-sm rounded" @click="removeUPI(value)"><i class="uil uil-trash-alt"></i> Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="returnData.length">
                  <div class="col mt-3 d-none">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          hide-ellipsis
                          @change="handlePageChange"
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="totalData"
                        >
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="card-body border-top">
            <div class="row align-items-center">
              <div class="col-12 mt-3">
                <h4 class="fw-medium font-size-18 text-info mb-3 p-0">Bank Account List</h4>
              </div>
              <div class="col-12">
                <div class="d-md-flex">
                    <div class="d-flex">
                      <div class="app-search2 position-relative">
                        <input
                          type="search"
                          placeholder="Filter..."
                          class="form-control mb-3"
                          v-model.trim="searchKey2"
                          autocomplete="no"
                          @keyup="filterByIMPS()"
                        />
                        <span class="uil-search text-info"></span>
                      </div>
                      <button
                        class="btn btn-light mb-3 ms-2 text-nowrap"
                        type="button"
                        @click="clear()"
                      >
                        <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="ms-auto mb-3 mb-md-0 text-end">
                    <button type="button" class="btn btn-info fw-medium rounded" @click="$refs['modal-addbank'].show()"><i class="uil uil-plus-circle me-1"></i> Add Bank Account</button>
                  </div>
                  
                </div>
              </div>
              <div class="col-12 col-lg-auto d-none">
                <div class="row">
                  <div class="col-6 pe-1 d-grid">
                    <button
                      class="btn btn-light mb-3 text-nowrap"
                      type="button"
                      @click="clear()"
                    >
                      <i class="uil-redo me-1"></i> Reset
                    </button>
                  </div>
                  <div class="col-6 ps-1 d-grid">
                    <button
                      class="btn btn-info text-white mb-3 text-nowrap"
                      type="button"
                      @click="search()"
                    >
                      <i class="uil-search me-1"></i> Filter
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
                <div class="table-responsive text-nowrap font-size-14">
                  <table class="table mb-2 mb-0 align-middle min-width-760" :class="{'table-hover':returnData2.length>0}">
                    <thead class="text-uppercase bg-info text-white">
                      <tr>
                        <th>Bank Name</th>
                        <th>Bank Account No.</th>
                        <th>Bank Account Name</th>
                        <th>IFSC Code</th>
                        <th class="text-end">Transaction Limit</th>
                        <th class="text-end" width="120">Active</th>
                        <th class="text-end" width="120">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                    <tr v-if="!filterByIMPS().length && !loading2 && searchKey2">
                        <td colspan="7" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i>  Sorry, we couldn't
                          find any results of "{{ searchKey2 }}".
                        </td>
                      </tr>
                      <tr v-if="!returnData2.length && !loading2">
                        <td colspan="7" height="200" class="text-center text-muted">
                          <i class="uil uil-usd-circle me-1"></i> No Data Available
                        </td>
                      </tr>
                      <tr v-if="loading2">
                        <td colspan="6" class="text-center text-muted" height="300">
                          <div
                            class="spinner-border text-secondary my-2"
                            role="status"
                          >
                            <span class="sr-only">Loading...</span>
                          </div>
                          <br />
                          <div>Loading...</div>
                        </td>
                      </tr>
                      <tr v-for="(value, index) in filterByIMPS()" :key="index">
                        <td>
                          {{ value.bank_name }}
                        </td>
                        <td>
                          {{ value.bank_account_no }}
                        </td>
                        <td>
                          {{ value.bank_account_name }}
                        </td>
                        <td>
                          {{ value.ifsc_code }}
                        </td>
                        <td class="text-end">
                          {{  convertedTransactionLimit(value.transaction_limit)  }}
                        </td>
                        <td class="text-end">
                          <div class="form-check form-switch form-switch-md float-end">
                            <input class="form-check-input" type="checkbox" @change="updateBank(value)" role="switch" v-model="value.active" true-value="1" false-value="0" :disabled="submitted">
                          </div>
                        </td>
                        <td class="text-end">
                          <button type="button" class="btn btn-outline-danger btn-sm rounded" @click="removeBankAcc(value)"><i class="uil uil-trash-alt"></i> Remove</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="row" v-if="returnData2.length">
                  <div class="col mt-3 d-none">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          hide-ellipsis
                          @change="handlePageChange"
                          v-model="currentPage"
                          :per-page="perPage"
                          :total-rows="totalData"
                        >
                        </b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
            
      </div>
    </div>
    <Lottie :path="'animate/loading-168.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
    <b-modal
      @hidden="resetModal"
      ref="modal-addupi"
      centered
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3">Add UPI Account</h5>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label for="upi_id" class="form-label text-info">UPI ID</label>
            <input type="text" class="form-control" id="upi_id" placeholder="UPI ID" v-model.trim="addUPI.upi_id" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addUPI.upi_id.$error,
            }"
            >
            <div v-if="modalSubmit && $v.addUPI.upi_id.$error" class="invalid-feedback">
              <span v-if="!$v.addUPI.upi_id.required">UPI ID is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="upi_name" class="form-label text-info">UPI Name</label>
            <input type="text" class="form-control" id="upi_name" placeholder="UPI Name" v-model.trim="addUPI.upi_name" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addUPI.upi_name.$error,
            }">
            <div v-if="modalSubmit && $v.addUPI.upi_name.$error" class="invalid-feedback">
              <span v-if="!$v.addUPI.upi_name.required">UPI Name is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <label for="upi_id" class="form-label text-info">Type</label>
            <div>
            <input type="radio" class="btn-check" value="paytm" name="type" id="type_paytm" autocomplete="off" v-model="addUPI.upi_type">
            <label class="btn btn-md btn-outline-info me-2" for="type_paytm">PayTM</label>
            <input type="radio" class="btn-check" value="phonePe" name="type" id="type_phonepe" autocomplete="off" v-model="addUPI.upi_type">
            <label class="btn btn-outline-info" for="type_phonepe">PhonePe</label>
            </div>
            <div v-if="modalSubmit && $v.addUPI.upi_type.$error" class="invalid-feedback">
              <span v-if="!$v.addUPI.upi_type.required">Type is required.</span>
            </div>
          </div>
          <div class="mb-4">
            <label for="upi_transaction_limit" class="form-label text-info">Transaction Limit</label>
            <input type="tel" class="form-control" @input="inputAmount" @blur="updateAmount" v-model="addUPI.transaction_limit" id="upi_transaction_limit" placeholder="Transaction Limit" :class="{
              'is-invalid': modalSubmit && $v.addUPI.transaction_limit.$error,
            }">
             <div v-if="modalSubmit && $v.addUPI.transaction_limit.$error" class="invalid-feedback">
              <span v-if="!$v.addUPI.transaction_limit.required">Transaction Limit is required.</span>
            </div>
          </div>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-addupi'].hide()">Cancel</button>
            
            <button type="button" class="btn fw-medium btn-info" @click="addUPIRequest" :disabled="modalLoading">
              <span v-if="modalLoading"> Adding...</span>
              <span v-else>  Add UPI Account </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
        
      </div>
    </b-modal>

    <b-modal
      @hidden="resetModal"
      ref="modal-addbank"
      centered
      hide-footer
      hide-header
      :no-close-on-backdrop="true"
      content-class="overflow-hidden"
    >
      <div class="row align-items-center">
        <div class="col-12 text-white" >
          <h5 class="text-info mb-3">Add Bank Account</h5>
        </div>
        <div class="col-12">
          <div class="mb-3">
            <label class="form-label text-info">Bank Name</label>
            <input type="text" class="form-control" placeholder="Bank Name" v-model.trim="addBank.bank_name" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addBank.bank_name.$error,
            }"
            >
            <div v-if="modalSubmit && $v.addBank.bank_name.$error" class="invalid-feedback">
              <span v-if="!$v.addBank.bank_name.required">Bank Name is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label text-info">Bank Account No.</label>
            <input type="tel" class="form-control" @input="inputNumberOnly" placeholder="Bank Account No." v-model.trim="addBank.bank_account_no" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addBank.bank_account_no.$error,
            }">
            <div v-if="modalSubmit && $v.addBank.bank_account_no.$error" class="invalid-feedback">
              <span v-if="!$v.addBank.bank_account_no.required">Bank Account No. is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label text-info">Bank Account Name</label>
            <input type="text" class="form-control" placeholder="Bank Account Name" v-model.trim="addBank.bank_account_name" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addBank.bank_account_name.$error,
            }">
            <div v-if="modalSubmit && $v.addBank.bank_account_name.$error" class="invalid-feedback">
              <span v-if="!$v.addBank.bank_account_name.required">Bank Account Name is required.</span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label text-info">IFSC Code</label>
            <input type="text" class="form-control" placeholder="IFSC Code" v-model.trim="addBank.ifsc_code" autocomplete="off"
            :class="{
              'is-invalid': modalSubmit && $v.addBank.ifsc_code.$error,
            }">
            <div v-if="modalSubmit && $v.addBank.ifsc_code.$error" class="invalid-feedback">
              <span v-if="!$v.addBank.ifsc_code.required">IFSC Code is required.</span>
            </div>
          </div>
          
          <div class="mb-4">
            <label for="upi_transaction_limit" class="form-label text-info">Transaction Limit</label>
            <input type="tel" class="form-control" @input="inputAmount" @blur="updateAmount" v-model="addUPI.transaction_limit" id="upi_transaction_limit" placeholder="Transaction Limit" :class="{
              'is-invalid': modalSubmit && $v.addBank.transaction_limit.$error,
            }">
             <div v-if="modalSubmit && $v.addBank.transaction_limit.$error" class="invalid-feedback">
              <span v-if="!$v.addBank.transaction_limit.required">Transaction Limit is required.</span>
            </div>
          </div>
          <div class="d-grid gap-2 d-md-block text-end">
            <button type="button" class="btn fw-medium btn-secondary me-md-2" @click="$refs['modal-addbank'].hide()">Cancel</button>
            
            <button type="button" class="btn fw-medium btn-info" @click="addBankAccRequest" :disabled="modalLoading">
              <span v-if="modalLoading"> Adding...</span>
              <span v-else>  Add Bank Account </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="modalLoading" role="status" aria-hidden="true"></span>
            </button>
          </div>
        </div>
        
      </div>
    </b-modal>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}

/**
 * Starter page
 */
 const PageTitle = "Bank Accounts"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      search_value:'',
      searchKey:'',
      searchKey2:'',
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "One68Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "YOUR_API_ID",
        apiHash:"YOUR_API_HASH",
      },
      currentPage:1,
      perPage: 20,
      totalData: 0,
      submitted:false,
      modalSubmit:false,
      modalLoading:false,
      loading:false,
      loading2:false,
      buttonLoading:[false,false,false],
      returnData: [],
      returnData2: [],
      addUPI:{
        upi_id: "",
        upi_name: "",
        upi_type: "paytm",
        transaction_limit: "10,000",
      },
      addBank:{
        bank_name: "",
        bank_account_no: "",
        bank_account_name: "",
        ifsc_code: "",
        transaction_limit: "10,000",
      }
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
    },
    addUPI: {
      upi_id: {
        required,
      },
      upi_name: {
        required,
      },
      upi_type: {
        required,
      },
      transaction_limit: {
        required,
      }
    },
    addBank: {
      bank_name: {
        required,
      },
      bank_account_no: {
        required,
      },
      bank_account_name: {
        required,
      },
      ifsc_code: {
        required,
      },
      transaction_limit: {
        required,
      },
    }
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    console.log( this.$refs.commonFunc.getPhone())
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    await this.getData();
    await this.getData2();
    
  }, 
  created(){
   
  },
  methods:{
    handlePageChange(current_page) {
      this.pageNum = current_page;
      this.currentPage = current_page;
      //this.getData(this.pageNum, this.search_value);
      //this.exportData(1)
    },
    convertedTransactionLimit(value) {
      // Assuming this.transactionLimit is defined in your component's data
      return this.$refs.commonFunc.convertCurrencyFormat(value).replaceAll(".00","");
    },
    inputNumberOnly(event) {
        var numericValue = event.target.value.replace(/[^0-9]/g, '');
        this.addBank.bank_account_no = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
    },
    inputAmount(event) {
        const numericValue = event.target.value.replace(/[^0-9.]/g, '');
        this.addUPI.transaction_limit = numericValue;
        this.addBank.transaction_limit = numericValue;
        if (event.target.value !== numericValue) {
            return
        }
    },
    updateAmount() {
        const numericValue = this.addUPI.transaction_limit.replace(/[^0-9.]/g, '');
        if (numericValue !== '') {
            const amountNumber = parseInt(numericValue);
            if (!isNaN(amountNumber)) {
                this.addUPI.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
                this.addBank.transaction_limit = amountNumber.toLocaleString('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                    useGrouping: true
                });
            } else {
              this.addUPI.transaction_limit = '10,000';
              this.addBank.transaction_limit = '10,000';
            }
        } else {
          this.addUPI.transaction_limit = '10,000';
          this.addBank.transaction_limit = '10,000';
        }
    },
    resetModal() {
      this.modalSubmit = false;
      this.modalLoading = false;
      this.addUPI={
        upi_id: "",
        upi_name: "",
        upi_type: "paytm",
        transaction_limit: "10,000",
      }
      this.addBank={
        bank_name: "",
        bank_account_no: "",
        bank_account_name: "",
        ifsc_code: "",
        transaction_limit: "10,000",
      }
    },
    async getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            
              this.returnData = [
                {
                id: '1',
                upi_id: '919042635795@paytm',
                upi_name: 'Charli',
                type: 'Paytm',
                transaction_limit: '10000',
                active:1
                },
                {
                id: '2',
                upi_id: '919042635795@ibl',
                upi_name: 'Charli',
                type: 'PhonePe',
                transaction_limit: '10000',
                active:1
                },
                {
                id: '3',
                upi_id: '91922242635795@paytm',
                upi_name: 'Jekyll',
                type: 'Paytm',
                transaction_limit: '10000',
                active:0
                }
              ]
              this.reload()
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    async getData2(){
        this.$Progress.start();
        this.loading2 = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
            
              this.returnData2 = [
                {
                id: '1',
                bank_account_no: '10012096825',
                bank_account_name: 'CHARLIE G',
                bank_name: 'IndusInd Bank',
                ifsc_code: 'INDB0000135',
                transaction_limit: '12000',
                active:1
                },
                {
                id: '2',
                bank_account_no: '30032094591',
                bank_account_name: 'CHARLIE G',
                bank_name: 'Axis Bank',
                ifsc_code: 'AXB2000439',
                transaction_limit: '10000',
                active:1
                },
                {
                id: '3',
                bank_account_no: '20012096825',
                bank_account_name: 'NAJIB GOR',
                bank_name: 'Maybank Bank',
                ifsc_code: 'MBB0000321',
                transaction_limit: '12000',
                active:0
                },
              ]
              this.reload()
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading2 = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading2 = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    updateUPI(value){
      this.$Progress.start();
      this.submitted = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("username",  this.accessUsername);
      bodyFormData.append("id", value.id);
      bodyFormData.append("active", value.active);
      axios({
          method: "post",
          url: appConfig.DemoAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          this.returnData = []
          if (resData.status == 200) {
            console.log(resData)
            this.getData();
          } 
          else if (resData.status == 401){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.submitted = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    updateBank(value){
      this.$Progress.start();
      this.submitted = true
      var bodyFormData = new FormData();
      bodyFormData.append("accessToken", this.accessToken);
      bodyFormData.append("username",  this.accessUsername);
      bodyFormData.append("id", value.id);
      bodyFormData.append("active", value.active);
      axios({
          method: "post",
          url: appConfig.DemoAPI,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response)=>{
          var resData = response.data;
          this.returnData2 = []
          if (resData.status == 200) {
            console.log(resData)
            this.getData2();
          } 
          else if (resData.status == 401){
            Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
              this.$refs.commonFunc.clearData()
              this.$router.push({
                  path: "/login",
            });
          }
          else {
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${resData.message}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
          }
          this.submitted = false;
          this.$Progress.finish();
        })
        .catch((error)=> {
            this.submitted = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    removeUPI(value) {
      Swal.fire(
        {
        icon: 'info',
        title: 'Confirm Removal of UPI ID',
        html: `Are you sure you want to remove UPI ID ${value.upi_id} (${value.upi_name})?`,
        confirmButtonColor: '#222',
        confirmButtonText: "Yes, Remove",
        showCancelButton: true,
        cancelButtonColor: '#aaa',
        reverseButtons: true
      }).then((response) => {
          if (response.isConfirmed){
              this.$Progress.start();
              this.submitted = true
              var bodyFormData = new FormData();
              bodyFormData.append("accessToken", this.accessToken);
              bodyFormData.append("username",  this.accessUsername);
              bodyFormData.append("id", value);
              axios({
                  method: "post",
                  url: appConfig.DemoAPI,
                  data: bodyFormData,
                  headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response)=>{
                  this.returnData = []
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    this.getData();
                  } 
                  else if (resData.status == 401){
                    Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      this.$refs.commonFunc.clearData()
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else {
                      Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                  }
                  this.submitted = false;
                  this.$Progress.finish();
                })
                .catch((error)=> {
                    this.submitted = false;
                    this.$Progress.finish();
                    //Swal.fire("Error", error, "error");
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${error}.`,
                      confirmButtonColor: '#222',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
              });
          }
      })
    },
    removeBankAcc(value) {
      Swal.fire(
        {
        icon: 'info',
        title: 'Confirm Removal of Bank Account',
        html: `Are you sure you want to remove bank account ${value.bank_account_no} (${value.bank_account_name})?`,
        confirmButtonColor: '#222',
        confirmButtonText: "Yes, Remove",
        showCancelButton: true,
        cancelButtonColor: '#aaa',
        reverseButtons: true
      }).then((response) => {
          if (response.isConfirmed){
              this.$Progress.start();
              this.submitted = true
              var bodyFormData = new FormData();
              bodyFormData.append("accessToken", this.accessToken);
              bodyFormData.append("username",  this.accessUsername);
              bodyFormData.append("id", value);
              axios({
                  method: "post",
                  url: appConfig.DemoAPI,
                  data: bodyFormData,
                  headers: { "Content-Type": "multipart/form-data" },
              })
              .then((response)=>{
                  this.returnData2 = []
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    this.getData2();
                  } 
                  else if (resData.status == 401){
                    Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                      this.$refs.commonFunc.clearData()
                      this.$router.push({
                          path: "/login",
                    });
                  }
                  else {
                      Swal.fire(
                        {
                        icon: 'error',
                        title: 'Oops...',
                        html: `${resData.message}.`,
                        confirmButtonColor: '#222',
                        confirmButtonText: this.$t('siteLang.Done'),
                      })
                  }
                  this.submitted = false;
                  this.$Progress.finish();
                })
                .catch((error)=> {
                    this.submitted = false;
                    this.$Progress.finish();
                    //Swal.fire("Error", error, "error");
                    Swal.fire(
                      {
                      icon: 'error',
                      title: 'Oops...',
                      html: `${error}.`,
                      confirmButtonColor: '#222',
                      confirmButtonText: this.$t('siteLang.Done'),
                    })
              });
          }
      })
    },
    addUPIRequest(){
      this.modalSubmit = true
      this.$v.addUPI.$touch();
      if (this.$v.addUPI.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        bodyFormData.append("upiID", this.addUPI.upi_id);
        bodyFormData.append("upiName", this.addUPI.upi_name);
        bodyFormData.append("type", this.addUPI.upi_type);
        bodyFormData.append("transactionLimit", this.addUPI.transaction_limit.replaceAll(",",""));

        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'New UPI Account Added',
                html: `UPI Account has been successfully added!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-addupi'].hide()
                this.getData();
              })
              
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
    addBankAccRequest(){
      this.modalSubmit = true
      this.$v.addBank.$touch();
      if (this.$v.addBank.$invalid) {
        return;
      } else {
        this.$Progress.start();
        this.modalLoading = true;
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("username",  this.accessUsername);
        bodyFormData.append("bankName", this.addBank.bank_name);
        bodyFormData.append("bankAccountNo", this.addBank.bank_account_no);
        bodyFormData.append("bankAccountName", this.addBank.bank_account_name);
        bodyFormData.append("ifscCode", this.addBank.ifsc_code);
        bodyFormData.append("transactionLimit", this.addBank.transaction_limit.replaceAll(",",""));
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            this.returnData2 = []
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              Swal.fire({
                icon: 'success',
                title: 'New Bank Account Added',
                html: `Bank Account has been successfully added!`,
                confirmButtonColor: '#FA6541',
                confirmButtonText: this.$t('siteLang.Done')
              }).then(() => {
                this.$refs['modal-addbank'].hide()
                this.getData2();
              })
              
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.modalLoading = false;
            this.modalSubmit = false
            this.$Progress.finish();
          })
          .catch((error)=> {
              this.modalLoading = false;
              this.modalSubmit = false
              this.$Progress.finish();
              //Swal.fire("Error", error, "error");
              Swal.fire(
                {
                icon: 'error',
                title: 'Oops...',
                html: `${error}.`,
                confirmButtonColor: '#222',
                confirmButtonText: this.$t('siteLang.Done'),
              })
        });
      }
    },
   
    clear(){
      this.searchKey=""
      this.searchKey2=""
    },
    filterByUPI() {
      return this.returnData.filter((e) => {
          return (
          e.upi_id.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.upi_name.toLowerCase().includes(this.searchKey.toLowerCase()) ||
          e.type.toLowerCase().includes(this.searchKey.toLowerCase())
          );
      })
    },
    filterByIMPS() {
      return this.returnData2.filter((e) => {
          return (
          e.bank_name.toLowerCase().includes(this.searchKey2.toLowerCase()) ||
          e.bank_account_no.toLowerCase().includes(this.searchKey2.toLowerCase()) ||
          e.bank_account_name.toLowerCase().includes(this.searchKey2.toLowerCase()) ||
          e.ifsc_code.toLowerCase().includes(this.searchKey2.toLowerCase())
          );
      })
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.formData.apiID = data.apiID;
          this.formData.apiHash = data.apiHash;
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },

  }
};
</script>